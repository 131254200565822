import { useContext, useState } from "react";
import { IGroup } from "../../types";
import { Collapse } from "react-collapse";
import Query from "./Query";
import { ColorsContext } from "../../contexts/ColorsContext";

interface IGroupProps {
	group: IGroup;
}

const Group: React.FC<IGroupProps> = ({ group }) => {
	const queries = group.filterData.queries;
	const [open, setOpen] = useState(false);
	const colors = useContext(ColorsContext);

	return (
		<div className="tw-py-2" style={{ borderColor: colors.lines }}>
			asdsadasd
			{queries.length > 1
				? queries
					.filter((query) => query.name !== "ALL")
					.map((query) => (
						<Query
							key={`${group.name}-${query.name}`}
							group={group}
							query={query}
						/>
					))
				: queries.map((query) => (
					<Query
						key={`${group.name}-${query.name}`}
						group={group}
						query={query}
					/>
				))}

		</div>
	);
};

export default Group;
