import React, { useContext } from "react";
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { PlayerContext } from "../../../../contexts/PlayerContext";
import { ShapesContext } from "../../../../contexts/ShapesContext";
import { IParameterProps } from "../Parameter";

interface IPredefinedButton {
	value: string;
	label: string;
	selected: boolean;
	onClick: (val: string) => void;
}

export const PredefinedButton: React.FC<IPredefinedButton> = ({
	value,
	label,
	selected,
	onClick,
}) => {
	const colors = useContext(ColorsContext);
	const shapes = useContext(ShapesContext);

	return (
		<button
			type="button"
			value={value}
			className={`tw-border tw-px-2 tw-py-1 button-hover`}
			style={{
				borderColor: colors.inputLines,
				color: selected ? colors.textActive : colors.text,
				background: selected ? colors.bgActive : colors.bg,
				borderRadius: shapes.buttonRadius,
			}}
			onClick={() => onClick(value)}
		>
			{label}
		</button>
	);
};

const Predefined: React.FC<IParameterProps> = ({ parameter }) => {
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);

	const onClick = (value: string) => {
		if (!playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, value);
	};

	const visibilites =
		playerContext?.player.mediator.getNumberPredefinedOptionsVisibilities(
			parameter
		);

	return (
		<div
			className="Parameter tw-flex tw-flex-col tw-py-4"
			style={{ color: colors.text, borderColor: colors.lines }}
		>
			<div className="tw-flex tw-text-sm tw-justify-between tw-mb-2">
				<h3 className="tw-uppercase tw-break-all">{parameter.name}</h3>
				<span>{parameter.unit}</span>
			</div>
			<span className="tw-text-sm">
				<div className="tw-flex tw-flex-wrap tw-gap-3">
					{Object.keys(parameter.settings.options).map((key, i) => {
						const value = parameter.settings.options[key];
						if (!visibilites[value]) return null;

						const label = parameter.settings.optionsLabels[key];
						const selected = parameter.value === value;

						return (
							<PredefinedButton
								key={`${parameter.code}-${value}-${i}`}
								label={label}
								value={value}
								selected={selected}
								onClick={onClick}
							/>
						);
					})}
				</div>
			</span>
		</div>
	);
};

export default Predefined;
