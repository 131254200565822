import React, { useContext, useEffect, useState } from "react";
import { PlayerContext } from "../../../../contexts/PlayerContext";
import { IParameterProps } from "../Parameter";
import { DebounceInput } from "react-debounce-input";
import { convertUnit } from "../../../../utils/converter";
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { ShapesContext } from "../../../../contexts/ShapesContext";

const Range: React.FC<IParameterProps> = ({ parameter }) => {
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);
	const shapes = useContext(ShapesContext);
	const [paramValue, setParamValue] = useState<string>(parameter.valueLabel);

	useEffect(() => {
		setParamValue(parameter.valueLabel);
	}, [parameter.valueLabel]);

	const onRangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target || !playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, e.target.value);
	};

	const onRangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target || !playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		const convertedUnitValue =
			player.targetUnit !== "mm"
				? player.mediator.convert(e.target.value, "mm", player.targetUnit)
				: e.target.value;
		setParamValue(convertedUnitValue);
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!e.target || !playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		const valueInput = e.target.value;

		const { value, inputValue } = convertUnit(
			player,
			valueInput,
			parameter.unit,
			parameter.settings.range.min,
			parameter.settings.range.max
		);

		e.target.value = inputValue;

		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, value);
	};

	return (
		<div
			className="Parameter tw-flex tw-flex-col tw-py-4"
			style={{ color: colors.text, borderColor: colors.lines }}
		>
			<h3 className="tw-text-sm tw-uppercase tw-mb-2 tw-break-all">
				{parameter.name}
			</h3>
			<span className="tw-text-sm">
				<div className="tw-flex tw-gap-4">
					<div className="tw-flex tw-flex-col tw-flex-1 tw-pt-2">
						<DebounceInput
							debounceTimeout={500}
							type="range"
							className="tw-w-full tw-max-h-4"
							value={parameter.value}
							min={parameter.settings.range.min}
							max={parameter.settings.range.max}
							onChange={onRangeChange}
							onInput={onRangeInput}
							style={{
								borderColor: colors.inputLines,
								color: colors.text,
								"--rangeThumbBg": colors.rangeThumbBg,
								"--rangeThumbBorder": colors.rangeThumbBorder,
								"--rangeThumbBorderRadius": shapes.rangeThumbRadius,
								borderRadius: shapes.rangeRadius,
							}}
						/>
						<div className="tw-flex tw-justify-between tw-text-xs">
							<span>
								{parameter.settings.range.minLabel} {parameter.unitLabel}
							</span>
							<span>
								{parameter.settings.range.maxLabel} {parameter.unitLabel}
							</span>
						</div>
					</div>
					<DebounceInput
						type="number"
						debounceTimeout={750}
						value={paramValue}
						className="tw-border tw-w-20 tw-max-h-8 tw-border-gray-300 tw-rounded-full tw-text-center tw-py-1"
						onChange={onInputChange}
						style={{
							borderColor: colors.inputLines,
							color: colors.text,
							borderRadius: shapes.inputRadius,
						}}
					/>
				</div>
			</span>
		</div>
	);
};

export default Range;
