import { useContext, useEffect, useState } from "react";
import { ColorsContext } from "../../contexts/ColorsContext";
import { PlayerContext } from "../../contexts/PlayerContext";
import { IGroup, IMaterial, IQuery, IResponseMetadata } from "../../types";
import Material from "./Material";

interface IQueryProps {
	group: IGroup;
	query: IQuery;
}

const Query: React.FC<IQueryProps> = ({ group, query }) => {
	const [materials, setMaterials] = useState<IMaterial[]>([]);
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);

	useEffect(() => {
		if (!playerContext || !playerContext.player) return;

		const player = playerContext.player;
		player.setLayoutMediator
			.getMaterialList(group.filterData.id, query)
			.then(
				(mats: { items: IMaterial[]; meta: IResponseMetadata }) => mats.items
			)
			.then((mats: IMaterial[]) => setMaterials([...mats]));
	}, [playerContext, group.filterData.id, query]);

	return (
		<div className="tw-grid tw-grid-cols-4 tw-gap-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
			{materials.map((material) => (
				<>
					<Material key={material.id} group={group} material={material} />
				</>
			))}
		</div>
	);
};

export default Query;
