import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { PlayerContext } from "./contexts/PlayerContext";
import { ColorsContext, defaultColors } from "./contexts/ColorsContext";
import { defaultShapes, ShapesContext } from "./contexts/ShapesContext";

const rootDOM = document.getElementById("variantic-layout") as HTMLElement;
const root = ReactDOM.createRoot(rootDOM);

const props: any = rootDOM.dataset;

props["cartUrl"] = rootDOM.getAttribute("cart-url")
props["cartType"] = rootDOM.getAttribute("cart-type")
props["hidePrice"] = rootDOM.getAttribute("hide-price")
props["hideBasket"] = rootDOM.getAttribute("hide-basket")

const colorsProps = Object.keys(props)
	.filter((key) => key.startsWith("color"))
	.reduce((acc: any, key: string) => {
		let newKey = key.replace("color", "");
		newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);
		acc[newKey] = props[key];
		delete props[key];
		return acc;
	}, {});

const colors = {
	...defaultColors,
	...colorsProps,
};

const shapesProps = Object.keys(props)
	.filter((key) => key.startsWith("shape"))
	.reduce((acc: any, key: string) => {
		let newKey = key.replace("shape", "");
		newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1);

		acc[newKey] = props[key];
		delete props[key];
		return acc;
	}, {});

const shapes = {
	...defaultShapes,
	...shapesProps,
};

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<PlayerContext.Provider
				value={{
					player: null,
					webComponent: null,
				}}
			>
				<ShapesContext.Provider value={shapes}>
					<ColorsContext.Provider value={colors}>
						<App {...props} />
					</ColorsContext.Provider>
				</ShapesContext.Provider>
			</PlayerContext.Provider>
		</Provider>
	</React.StrictMode>
);
