import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { View } from "../types";
import { RootState } from "./store";

interface IModesState {
	playerLoaded: boolean;
	fullscreen: boolean;
	view: View;
	documentationLoading: boolean;
	arLoading: boolean;
	snapshotLoading: boolean;
}

const initialState: IModesState = {
	playerLoaded: false,
	fullscreen: false,
	view: "edit",
	documentationLoading: false,
	arLoading: false,
	snapshotLoading: false,
};

export const modesSlice = createSlice({
	name: "modes",
	initialState,
	reducers: {
		setPlayerLoaded: (state, action: PayloadAction<boolean>) => {
			state.playerLoaded = action.payload;
		},
		toggleFullscreen: (state) => {
			state.fullscreen = !state.fullscreen;
		},

		toggleView: (state) => {
			state.view = state.view === "edit" ? "materials" : "edit";
		},
		toggleEdit: (state) => {
			state.view = "edit";
		},
		toggleAccessory: (state) => {
			state.view = "accessories";
		},
		toggleSummary: (state) => {
			state.view = "summary";
		},

		setDocumentationLoading: (state, action: PayloadAction<boolean>) => {
			state.documentationLoading = action.payload;
		},
		setArLoading: (state, action: PayloadAction<boolean>) => {
			state.arLoading = action.payload;
		},
		setSnapshotLoading: (state, action: PayloadAction<boolean>) => {
			state.snapshotLoading = action.payload;
		},
	},
});

export const {
	setPlayerLoaded,
	toggleFullscreen,
	toggleView,
	toggleAccessory,
	toggleSummary,
	toggleEdit,
	setDocumentationLoading,
	setArLoading,
	setSnapshotLoading,
} = modesSlice.actions;

// selectors
export const getModes = (state: RootState) => state.modesSlice;
export const getPlayerLoaded = createSelector(
	getModes,
	(state) => state.playerLoaded
);
export const getFullscreen = createSelector(
	getModes,
	(state) => state.fullscreen
);
export const getView = createSelector(getModes, (state) => state.view);

export default modesSlice.reducer;
