import { HtmlHTMLAttributes, useContext, useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "./components/layout/Layout";
import Player from "./components/Player";
import PlayerLayer from "./components/PlayerLayer";
import { ColorsContext } from "./contexts/ColorsContext";
import { getBasket, setSaveddata, getPrice } from "./redux/configurationSlice";
import { getTranslations, setTranslation } from "./redux/translationSlice";
import { useAppDispatch } from "./redux/hooks";
import { useAppSelector } from "./redux/hooks";
import { getFullscreen } from "./redux/modesSlice";
import { PlayerContext } from "./contexts/PlayerContext";


interface IAppProps {
	id?: string;
	name?: string;
	langCode?: string;
	configurationId?: string;
	dataToLoad?: string;
	documentationTitle?: string;
	hideDocumentation?: boolean;
	hidePrice?: boolean;
	hideBasket?: boolean;
	cartUrl?: string;
	cartType?: string;
}

const App: React.FC<IAppProps> = ({
	id,
	name,
	langCode,
	configurationId,
	dataToLoad,
	documentationTitle,
	hideDocumentation,
	hidePrice,
	hideBasket,
	cartUrl,
	cartType

}) => {
	const basket = useSelector(getBasket);
	const price = useSelector(getPrice);
	const fullscreen = useAppSelector(getFullscreen);
	const colors = useContext(ColorsContext);
	const dispatch = useAppDispatch();
	dispatch(setTranslation(langCode));
	const currenttranslation = useSelector(getTranslations);
	const [lsdata, setLsdata] = useState([]);




	let islsdataLoaded = false;
	function openSaveMenu(){
		if(!islsdataLoaded){
			islsdataLoaded = true;
			var lsobj = localStorage.getItem("B24_configurator_save"); 
			if(lsobj != null){
				setLsdata(JSON.parse(lsobj));
			}
		}
		const menu = document.querySelector("#SaveConfigData");
		if(menu?.classList.contains("SaveConfigData--active")){
			menu?.classList.remove("SaveConfigData--active")
		} else  {
			menu?.classList.add("SaveConfigData--active")
		}
	}

	function saveConfigurationData(_playerElement) {
		let config = _playerElement.saveConfiguration();
		return config;
	}

	async function saveConfigData() {
		const playerElement = document.querySelector('variantic-player');
		const saveConfigData = await saveConfigurationData(playerElement);
		const configNameInput = document.querySelector('#configNameInput') as HTMLInputElement | null;

		if(configNameInput?.value != null){
			let reinput = /^.{3,}$/;
			let inpv = configNameInput.value;
			if(reinput.test(configNameInput?.value)){
				saveConftoLS(saveConfigData, inpv)
			} else  {
				validateSaveFile("#configNameInput")
			}
		} else {
			validateSaveFile("#configNameInput")
		}
	}

	async function loadSavedData(id){
		const playerElement = document.querySelector('variantic-player');
		playerElement?.setAttribute('configuration-id', id);
		// Tu wbic stan do reduxa
		dispatch(setSaveddata(id));
	}

	function saveConftoLS(confdata, name){
		let savedata = {name: name, data: confdata}
		var lsobj = localStorage.getItem("B24_configurator_save"); 
		let objlss;
		if(lsobj != null){
			let objadd = JSON.parse(lsobj);
			objadd.push(savedata);
			localStorage.setItem("B24_configurator_save", JSON.stringify(objadd));
			objlss = objadd;
			setLsdata(objlss)
		} else  {
			let objnew: any[] = [];
			objnew.push(savedata)
			objlss = [savedata];
			setLsdata(objlss)
			localStorage.setItem("B24_configurator_save", JSON.stringify([savedata]));
		}
	}

	function validateSaveFile(elem){
		const configNameElem = document.querySelector(elem) as HTMLInputElement | null;
		configNameElem?.classList.add("elem-required");
		configNameElem?.addEventListener("click", function() {
			configNameElem?.classList.remove("elem-required");
		}); 
	}

	if (!id) return null;

	const openRightMenu = () => {
		let rgtcol = document.querySelector("#RightColHolder");
		let topbar = document.querySelector("#top-bar-holder")
		if (rgtcol?.classList.contains("right-column-holder--active")) {
			rgtcol?.classList.remove("right-column-holder--active")
		} else {
			rgtcol?.classList.add("right-column-holder--active")
		}
		if (topbar?.classList.contains("top-bar-holder--active")) {
			topbar?.classList.remove("top-bar-holder--active")
		} else {
			topbar?.classList.add("top-bar-holder--active")
		}
	};

	async function addBasket()  {

		if(cartType === "u"){window.location.href = cartUrl + basket;}
		if(cartType === "p"){
			interface fetchurl {name: any;}
			const url: fetchurl = {name: cartUrl,};
			const purl: any = url.name!;
			console.log(purl)
			const response = await fetch(purl, {
				method: "POST",
				headers: { "Content-Type": "application/json", },
				body: JSON.stringify(basket),
			});
		}
	}

	const AddBasketButton = (pric) => {
		if(pric.pric === "0zł z VAT 23%" || pric.pric === ""){
			return <a className="addCart-button-disabled">{currenttranslation?.translations?.basket_add}</a>
		} else {
			return <a onClick={addBasket} className="addCart-button">{currenttranslation?.translations?.basket_add}</a>
		}
	}


	   



	return (
		<>
			<div
				id="top-bar-holder" className="top-bar-holder tw-flex tw-justify-between tw-p-2 tw-text-2xl tw-border-b"
				style={{ color: colors.text, borderColor: colors.lines }}
			>
				<span className="app-name-holder"><div className="logo-holder"><img src="https://www.batna24.com/js/configurator-rack/icons/logo_extralink.svg" /></div>{currenttranslation?.translations?.cabinet_configurator}</span>

				<div className="addCart-holder">
					{!hidePrice &&
						<span className="addCart-price">{price}</span>
					}
					{!hideBasket &&
						<div className="add-cart-info-desktop">{currenttranslation?.translations?.warn1} <img src="https://www.batna24.com/js/configurator-rack/icons/check-circled-outline.svg"></img></div>
					}
					{cartUrl === "" || hideBasket
					? ""
					:(
						<AddBasketButton pric={price}/>
					)
					}

				</div>
				<div className="add-cart-info">{currenttranslation?.translations?.warn1} <img src="https://www.batna24.com/js/configurator-rack/icons/check-circled-outline.svg"></img></div>
			</div>

			<div id="SaveConfigData" className="SaveConfigData">
				<div className="SaveConfigData--content">
					<div className="SaveConfigData--button-holder">
						<input id="configNameInput" className="SaveConfig-input" type="text" placeholder="Wpisz nazwę konfiguracji"></input>
						<a className="SaveConfig-button" onClick={saveConfigData}>Zapisz aktualną konfigurację</a>
					</div>
					
					<div>Aktualnie zapisane konfiguracje:</div>
					<div className="config-list-holder">
						<ul>
							{lsdata && lsdata.map(({ idx, name, data }: any) =>
                        		<li className="config-list-elem" onClick={()=>loadSavedData(JSON.parse(data).id)}><div>{name} </div><span>Wczytaj</span></li>
                    		)}
						</ul>
					</div>
				</div>
				<div className="SaveConfigData--header" onClick={openSaveMenu}>Zapisz konfigurację</div>
			</div>

			<div className="App tw-w-full tw-h-full tw-relative tw-overflow-hidden app-holder mob-h-100">
				<div
					id="PlayerHolder"
					className={`${fullscreen ? "left-collapsed" : ""
						} w-full  lg:tw-w-3/4 tw-transition-all tw-duration-500 tw-flex tw-flex-col tw-h-3/4 md:tw-h-full`}
				>
					<div className="tw-flex-1 tw-relative">
						<PlayerLayer hideDocumentation={!!hideDocumentation} />
						<Player
							id={id}
							langCode={langCode ?? "pl"}
							documentationTitle={documentationTitle ?? ""}
							configurationId={configurationId ?? ""}
							dataToLoad={dataToLoad ?? ""}
							cartUrl={cartUrl ?? ""}
						/>
					</div>
				</div>

				<div className="configure-open-mobile-holder">
					<button className="configure-open-mobile" onClick={openRightMenu}>{currenttranslation?.translations?.cabinet_configuration}<img src="https://www.batna24.com/js/configurator-rack/icons/ico_arrow_top.svg"/></button>
					
				</div>
				<div
					id="RightColHolder"
					className={`${fullscreen ? "right-collapsed" : ""
						} right-column-hoolder tw-absolute tw-bottom-0 tw-left-0 md:tw-left-[unset] md:tw-bottom-[unset] md:tw-right-0 md:tw-top-0 tw-transition-all tw-duration-500 tw-h-1/4 tw-w-full tw-border tw-border-l-0 md:tw-h-full  `}
					style={{ borderColor: colors.lines }}
				>
					<button className="configure-close-mobile-holder" onClick={openRightMenu}>
						<div className="close-txt">{currenttranslation?.translations?.close}</div>
						<div className="close-icon"><img src="https://www.batna24.com/js/configurator-rack/icons/rclose.svg" /></div>
					</button>
					<Layout />
				</div>
			</div>

		</>
	);
};

export default App;
