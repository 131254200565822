import { configureStore } from "@reduxjs/toolkit";
import configurationSlice from "./configurationSlice";
import materialsSlice from "./materialsSlice";
import modesSlice from "./modesSlice";
import translationSlice from "./translationSlice"

export const store = configureStore({
	reducer: {
		configurationSlice: configurationSlice,
		modesSlice: modesSlice,
		materialsSlice: materialsSlice,
		translationSlice: translationSlice,
	},
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
