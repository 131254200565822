import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { IGroup } from "../types";
import { RootState } from "./store";

type TSelectedMaterials = { [key: number]: number };

interface IMaterialsState {
	groups: IGroup[];
	selectedMaterials: TSelectedMaterials;
}

const initialState: IMaterialsState = {
	groups: [],
	selectedMaterials: {},
};

export const materialsSlice = createSlice({
	name: "materials",
	initialState,
	reducers: {
		setGroups: (state, action: PayloadAction<IGroup[]>) => {
			state.groups = action.payload;
		},
		setSelectedMaterials: (
			state,
			action: PayloadAction<TSelectedMaterials>
		) => {
			state.selectedMaterials = {
				...action.payload,
			};
		},
	},
});

export const { setGroups, setSelectedMaterials } = materialsSlice.actions;

// selectors
export const getMaterialsSlice = (state: RootState) => state.materialsSlice;
export const getGroups = createSelector(
	getMaterialsSlice,
	(state) => state.groups
);
export const getSelectedMaterials = createSelector(
	getMaterialsSlice,
	(state) => state.selectedMaterials
);

export default materialsSlice.reducer;
