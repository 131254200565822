import { AnyListenerPredicate } from "@reduxjs/toolkit";
import { ICategoryWithParameters } from "../../types";
import Parameter from "./parameter/Accessory";


interface ICategoryProps {
    category: ICategoryWithParameters;
    actibutt: string;
    transl: any;
}

const AccessorySelect: React.FC<ICategoryProps> = ({ category, actibutt, transl }) => {
    return (
        <>
            {category.parameters
                .filter((p) => p.visible)
                .map((p, idx) => (
                    <>
                    <div key={idx} className={actibutt == p.code ? "AccessorySelect AccessorySelect--active" : "AccessorySelect"}>
                        <div>{transl.translations.choose_product_position} {p.code}</div>
                        <Parameter key={p.code} parameter={p} />
                    </div>
                    </>
                ))
            }
        </>
    );
};

export default AccessorySelect;
