import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ColorsContext } from "../../../../contexts/ColorsContext";
import { PlayerContext } from "../../../../contexts/PlayerContext";
import { ShapesContext } from "../../../../contexts/ShapesContext";
import { IParameterProps } from "../Parameter";
import { PredefinedButton } from "./Predefined";
import Select from 'react-select';
import { validateHeaderName } from "http";
import { getTranslations, setTranslation } from "../../../../redux/translationSlice";
import {getConfiguration, getCustomProdData} from "../../../../redux/configurationSlice";


type OptionType = { label: string; value: number };

interface IOptionsProps extends IParameterProps {
	onClick: (value: string) => void;
	visibilities: Record<number | string, boolean>;
}

const rootDOM = document.getElementById("variantic-layout") as HTMLElement;
const props: any = rootDOM.dataset;
props["cartUrl"] = rootDOM.getAttribute("cart-url")
props["cartType"] = rootDOM.getAttribute("cart-type")
props["hidePrice"] = rootDOM.getAttribute("hide-price")
props["hideBasket"] = rootDOM.getAttribute("hide-basket")



	const ListOptions: React.FC<IOptionsProps> = ({
		visibilities,
		parameter,
		onClick,
	}) => {

	const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const value = e.target.value;
		onClick(value);
	};

	interface IFooBar {
		label?: string;
		value?: string;
	}

	function selectCh(n1: string | number | object | null, position: any) {
		const obj = n1;
		type ObjectKey = keyof typeof obj;
		const myVar = 'value' as ObjectKey;
		if (obj != null) {
			onClick(obj[myVar])
			const input = document.querySelector(".accessorySelectSearchInput") as HTMLInputElement | null;
			if (input != null) {
				setProinput("")
				filterProducts("")
			}
		}
	}

	let scriptloaded  = false;
	function openprodDetails(dnx){

		

		let myContainer1 = document.getElementById('productDetailsDrawer_img') as HTMLInputElement;
		myContainer1.innerHTML = "";

		if(scriptloaded == false){
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = '/js/swiper.js';    
			document.head.appendChild(script);
			var link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = '/css/swiper.css';
			document.head.appendChild(link);
		}


		/*
			fetch(process.env.REACT_APP_URL1+'/pl/s/'+dnx, {
            	method: 'get',
        	})
		*/

		
		fetch(process.env.REACT_APP_URL1+'/pl/v2/product-details', {
            method: 'post',
			mode: "cors",
			credentials: "same-origin",
			headers: { "Content-Type": "application/json"},
			body: '{"product_id":"'+dnx+'"}'
        })
        .then(resp => {
            resp.text().then(c => {
                const o = JSON.parse(c);
				// add  images
				let pimages = "";
				{o.images.map((img)=>{
					pimages += '<div className="swiper-slide"><img src="https://www.batna24.com/img2/500/'+ img +'"/></div>';
				})}
				let myContainer = document.getElementById('productDetailsDrawer_img') as HTMLInputElement;
				
				myContainer.innerHTML = "";
				o.images.map((img)=>{
					let slide = document.createElement("div");
					slide.classList.add("swiper-slide");
					slide.innerHTML = '<img src="https://www.batna24.com/img2/500/'+img.url+'"/>';
					myContainer.appendChild(slide)
				});
				if(scriptloaded == false){
					var script = document.createElement('script');
					script.type = 'text/javascript';
					script.src = '/js/swiper_create.js';    
					document.head.appendChild(script);
				}
				// data
				let dataContainer = document.getElementById('productDetailsDrawer_data') as HTMLInputElement;
				dataContainer.innerHTML = "";

				let name = document.createElement("div")
				name.classList.add("pdd_data-name");
				name.innerHTML = o["name"];
				dataContainer.appendChild(name)

				let price = document.createElement("div")
				price.classList.add("pdd_data-price");
				let avail = `<div class="pdd_data-avi"><img src="/icons/check-circled-outline-green.svg"/><div>Dostępny</div></div>`
				if(o.qty == 0){avail = `<div class="pdd_data-avi pdd_data-avi-red"><img  src="/icons/warning-circled-outline.svg"/><div>Niedostępny</div></div>`}
				if(o.qty == 0 && o.eta != ""){avail = `<div class="pdd_data-avi pdd_data-avi-yellow"><img  src="/icons/warning-circled-outline.svg"/><div>Niedostępny</div></div> <div class="pdd_data-avi"><img src="/icons/alarm.svg"/><div>Najbliższa dostawa: `+o.eta+`</div></div>`}
				if(!props.hidePrice){
					price.innerHTML = `
					<div class="pdd_data-price--pricegross">`+o.price+` z VAT 23% (brutto)</span></div>
					<div class="pdd_data-price--pricenet">`+o.net_price+` bez VAT (netto)</div>
					`+avail+`
					`;
				}
				
				dataContainer.appendChild(price)

				if(o.description.length >  0){
					let desc = document.createElement("div")
					desc.classList.add("pdd_data-desc");
					desc.innerHTML = `
						<div class="section--header">O produkcie</div>
					`;
					o.description.map((des)=>{
						desc.innerHTML += `
						<div class="section--desc">`+des.desc+`</div>
						`;
					})

					dataContainer.appendChild(desc)
				}



				let specsContainer = document.getElementById('productDetailsDrawer_specs') as HTMLInputElement;
				specsContainer.innerHTML = "";
				let specholder = document.createElement("div");
				specholder.classList.add("section--specholder")



				if(Object.keys(o.features).length > 0){

					specholder.innerHTML = '<div class="section--header">Specyfikacja</div>'
					for (const [key, value] of Object.entries(o.features)) {
						let specitem = document.createElement("div");
						specitem.classList.add("section--specitem");
						specitem.innerHTML = '<div>'+key+'</div> <div>'+value+'</div>';
						specholder.appendChild(specitem);
					}
				}
				specsContainer.appendChild(specholder)
				scriptloaded = true;
			})
		})
		document.querySelector(".productDetailsDrawer")?.classList.add("productDetailsDrawer--active")
	}

	const [proinput, setProinput] = useState<any>("");
	const actualBom = useSelector(getConfiguration);
	const colors = useContext(ColorsContext);
	const shapes = useContext(ShapesContext);
	const currenttranslation = useSelector(getTranslations);

	const prodcustomdata = useSelector(getCustomProdData);

	let selectopt: (string | number | object)[] = [];
	let defval: (string | number | object)[] = [];
	let bomobj = actualBom.parameters.find((elem)=> elem.code === parameter.code)
	defval.push({ label:bomobj?.settings.options[bomobj?.value], value: bomobj?.value });

	let actip = {custom:{},name:"",status:false};
	if(bomobj?.code == parameter.code){
		if(bomobj.value > 1000){
			let value = bomobj.value
			let topush = {"name": bomobj.settings.options[value]}
			actip.name = bomobj.settings.options[value];
			actip.custom = prodcustomdata[value];
			actip.status = true;
		}
	}

	Object.keys(parameter.settings.options).map((id) => {
		let price = "-";
		let image = "nophoto.jpg";
		let dnx = "";
		let ean = "";
		let eta = "";
		let qty = "";
		if(prodcustomdata[id] != undefined){
			price = prodcustomdata[id]["pricePLN"]
			image = prodcustomdata[id]["img"]
			dnx = prodcustomdata[id]["dnx"]
			ean = prodcustomdata[id]["ean"]
			eta = prodcustomdata[id]["eta"]
			qty = prodcustomdata[id]["qty"]
		}
		if(id != "1000"){
			selectopt.push({ label: parameter.settings.options[id], value: id, img: image, price: price, dnx: dnx, ean: ean, eta: eta, qty: qty })
		}
	})
	
	const [prodlist, setProdlist] = useState<any[]>(selectopt);
	
	function filterProducts(event) {
		if(typeof event.target != "undefined" ){
			setProinput(event.target.value)
			if(event.target.value.length > 0){
				let filtered = selectopt.filter((item) => item["label"].toLowerCase().includes(event.target.value.toLowerCase()))
				setProdlist(filtered)
			}
		} else  {
			setProdlist(selectopt)
		}
	}
	function productStatus(qty, eta){
		let info;
		if(qty == 0 && eta != ""){info = <><div className="itemqty--status qitem-qty--status-yellow"><div className="ico"><img alt="case" src="/icons/warning-circled-outline.svg"/></div>produkt w trakcie dostawy</div><div className="itemqty--status qitem-qty--status-yellow">Planowana dostawa: {eta}</div></>} 
		else {
			if(qty > 0){info = <div className="itemqty--status qitem-qty--status-green"><div className="ico"><img alt="case" src="/icons/check-circled-outline.svg"/></div>produkt dostępny</div>}
			else{info = <div className="itemqty--status qitem-qty--status-red"><div className="ico"><img alt="case" src="/icons/warning-circled-outline.svg"/></div>produkt niedostępny</div>}
		}

		return <div>{info}</div>
	}

	return (
		<>
			{actip.status 
			? 
			(
			<div  className="selectedAccessory_holder">
				<div className="selectedAccessory_holder--price-name-holder">
					<div className="selectedName">
					<div className="snamme"><span>{currenttranslation.translations.choosen_accessories} </span>{actip.name}</div> 
						{productStatus(actip.custom["qty"], actip.custom["eta"])}
					</div>
				</div>

				<div className="selectedAccessory_holder-fcol">
					<div className="selectedAccessory_holder-frow">
						<a className="accselbutt_item--info" onClick={() => openprodDetails(actip.custom["dnx"])}><img src="info-empty.svg"/></a>
						<div className="selectedAccessory_holder--price">{actip.custom["pricePLN"]}</div>
					</div>
					<div onClick={() => selectCh({ label: "Pusty", value: "1000", img: "nophoto.jpg", price: "-" }, parameter.code)} className="selectedAccessory_holder--button">{currenttranslation.translations.remove}</div>
				</div>
				
			</div>
			)
			: ""
			}
			
			<div className="accessorySelectButtonHolder">
			<div className="accessorySelectSearch">
				<input
				className="accessorySelectSearchInput"
				 type="text" 
				 name="name" 
				 placeholder={currenttranslation.translations.search}
				 onChange={filterProducts}
				 value={proinput}
				 />
				<a><img src="/icons/Icon-rightsearch.svg"/></a>
			</div>

				{prodlist.map((opt, idx)=>(
					
					<div className="accessorySelectButtonHolder_item" key={idx}>

						<img className="accessory-item-img" src={"/products/"+opt["img"]}/>
						<div className="accselbutt_item--name">
							<div className="accselbutt_item--name-head">{opt["label"]}</div>
							<div>{productStatus(opt["qty"], opt["eta"])}</div>
						</div>
						
						{opt["dnx"] != "" 
						? 
						(<a className="accselbutt_item--info" onClick={() => openprodDetails(opt["dnx"])}><img src="info-empty.svg"/></a>)
						:
						(<img className="accselbutt_item--info--def" src="info-empty.svg"/>)
						}

						{!props.hidePrice &&
							<a className="accselbutt_item--price">{opt["price"]}</a>
						}
						
						{actip == opt["label"] 
						? 
						(
							<div className="accselbutt_item--add accselbutt_item--add-green">
								<div className="accselbutt_item--add_option_button_rem">Dodano</div>
							</div>
						)
						:
						(
							<div className="accselbutt_item--add" onClick={() => selectCh(opt, parameter.code)}>
								<div className="accselbutt_item--add_option_button">Dodaj</div>
							</div>
						)
						}
					</div>
				))}
			</div>
			
		</>
	);
};

const ButtonOptions: React.FC<IOptionsProps> = ({
	visibilities,
	parameter,
	onClick,
}) => {
	return (
		<div className="tw-flex flex-col tw-flex-wrap tw-gap-3">
			<div className="paramName">{parameter.name}</div>
			<div className="tw-flex tw-flex-wrap tw-gap-3">
				{Object.keys(parameter.settings.options).map((key, i) => {
					const value = key;
					if (!visibilities[value]) return null;
					const label = parameter.settings.options[key];
					const selected = String(parameter.value) === String(value);
					return (
						<PredefinedButton
							key={`${parameter.code}-${value}-${i}`}
							label={label}
							value={value}
							selected={selected}
							onClick={onClick}
						/>
					);
				})}
			</div>
		</div>
	);
};



const Options: React.FC<IParameterProps> = ({ parameter }) => {

	const OptionsComp =
		parameter.settings.listType === "1" ? ButtonOptions : ListOptions;
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);

	const visibilities =
		playerContext.player.mediator.getListOptionsVisibilities(parameter);

	const onClick = (value: string) => {
		if (!playerContext) return;
		const player = playerContext.player;
		if (!player) return;
		player.setSelected(player.setRoots.filterScenes()[0]);
		player.mediator.changeParameter(parameter.code, value);
	};

	useEffect(() => {
		if (!playerContext) return;

		const player = playerContext.player;
		if (!player) return;

		if (parameter.code !== 'kol') return;

		const materialGroupId = 4;

		// 'kol' parameter value mapped to material id 
		const materialsId: Record<number, number> = {
			1: 23,
			2: 24,
		}
		const materialId = materialsId[parameter.value];

		if (!materialId) {
			console.error(`Material ID not found for value "${parameter.value}"`);
			return;
		}

		player.setLayoutMediator.assignMaterial(
			materialGroupId,
			materialId
		);
	}, [playerContext, parameter.code, parameter.value])

	return (
		<div
			className="Parameter tw-flex tw-flex-col tw-py-4"
			style={{ color: colors.text, borderColor: colors.lines }}
		>
			<span className="tw-text-sm">
				<OptionsComp
					visibilities={visibilities}
					parameter={parameter}
					onClick={onClick}
				/>
			</span>
		</div>
	);
};

export default Options;
