import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ColorsContext } from "../contexts/ColorsContext";
import { PlayerContext } from "../contexts/PlayerContext";
import { useAppDispatch } from "../redux/hooks";
import { getTranslations, setTranslation } from "../redux/translationSlice";
import {
	getFullscreen,
	getModes,
	getPlayerLoaded,
	getView,
	setArLoading,
	setDocumentationLoading,
	setSnapshotLoading,
	toggleFullscreen,
	toggleView,
} from "../redux/modesSlice";

interface IPlayerLayerIconProps {
	name: string;
	styles: string;
	loading?: boolean;
	tooltipTitle?: string;
	onClick?: () => void;
}

const PlayerLayerIcon: React.FC<IPlayerLayerIconProps> = ({
	name,
	styles,
	loading,
	tooltipTitle,
	onClick,
}) => {
	const [tooltip, setTooltip] = useState(false);
	const colors = useContext(ColorsContext);
	return (
		<button
			onMouseEnter={() => setTooltip(true)}
			onMouseLeave={() => setTooltip(false)}
			className={`tw-z-50 tw-absolute ${styles}`}
			style={{}}
			type="button"
			onClick={onClick}
		>
			{tooltipTitle && (
				<span
					className={`${tooltip ? "" : "tw-hidden"
						} tw-absolute tw-right-0 tw-top-2 tw-px-1 tw-text-sm tw-whitespace-nowrap`}
					style={{
						transform: "translateX(-40px)",
						backgroundColor: colors.tooltipBg,
						color: colors.tooltipText,
					}}
				>
					{tooltipTitle}
				</span>
			)}
			<i
				className={`${loading ? "tw-animate-spin " : ""
					} material-icons tw-text-3xl`}
				style={{ color: colors.icons }}
			>
				{loading ? "rotate_right" : name}
			</i>
		</button>
	);
};

interface IPlayerLayerProps {
	hideDocumentation?: boolean;
}

const PlayerLayer: React.FC<IPlayerLayerProps> = ({ hideDocumentation }) => {
	const dispatch = useAppDispatch();
	const fullscreen = useSelector(getFullscreen);
	const view = useSelector(getView);
	const playerLaoded = useSelector(getPlayerLoaded);
	const { arLoading, documentationLoading, snapshotLoading } =
		useSelector(getModes);
	const playerContext = useContext(PlayerContext);
	const currenttranslation = useSelector(getTranslations);
	const isIOS = /Mobi|iPad|iPhone|iPod/i.test(navigator.userAgent);
	hideDocumentation = true;

	if (!playerLaoded) return null;

	const player = playerContext?.player;
	const translates = player?.mediator.translate("button") ?? {};


	const downloadDocumentation = () => {
		if (documentationLoading) return;
		dispatch(setDocumentationLoading(true));
		try {
			player.documentation
				.getSetDocumentations(player.setRoots)
				.catch(() => dispatch(setDocumentationLoading(false)))
				.then(() => dispatch(setDocumentationLoading(false)));
		} catch (e) {
			console.error(e);
			dispatch(setDocumentationLoading(false));
		}
	};

	const downloadSnapshot = () => {
		if (snapshotLoading) return;

		dispatch(setSnapshotLoading(true));

		try {
			playerContext.webComponent
				.takeASnapshot()
				.catch(() => dispatch(setSnapshotLoading(false)))
				.then(({ url }: any) => {
					window.open(url, "_blank");
					dispatch(setSnapshotLoading(false));
				});
		} catch (e) {
			console.error(e);
			dispatch(setSnapshotLoading(false));
		}
	};

	const downloadAr = () => {
		if (arLoading) return;

		dispatch(setArLoading(true));

		try {
			playerContext.webComponent
				.getGLTF()
				.catch(() => dispatch(setArLoading(false)))
				.then(() => dispatch(setArLoading(false)));
		} catch (e) {
			console.error(e);
			dispatch(setArLoading(false));
		}
	};

	return (
		<div className="PlayerLayerIcons">
			{hideDocumentation && (
				<button className="player_layer_ico pl_pdf" onClick={downloadDocumentation}>
					<div className="ico_mob"><img src="https://www.batna24.com/js/configurator-rack/icons/pl_ico_pdf.svg" alt="pdf" /></div>
					<div className="ico_desk"><img src="https://www.batna24.com/js/configurator-rack/icons/Icon-left-getpdf.svg" alt="pdf" />{currenttranslation.translations.get_summary}</div>
				</button>
			)}
			{!isIOS && (
				<button className="player_layer_ico pl_snap" onClick={downloadSnapshot}>
					<div className="ico_mob"></div>
					<div className="ico_desk"><img src="https://www.batna24.com/js/configurator-rack/icons/Icon-left-snap.svg" alt="snap" />{currenttranslation.translations.save_image}</div>
				</button>
			)}

			<button className="player_layer_ico pl_ar" onClick={downloadAr}>
				<div className="ico_mob"><img src="https://www.batna24.com/js/configurator-rack/icons/pl_ico_ar.svg" alt="ar" /></div>
				<div className="ico_desk"><img src="https://www.batna24.com/js/configurator-rack/icons/Icon-left-ar.svg" alt="snap" />{currenttranslation.translations.open_ar}</div>
			</button>

		</div>
	);
};

export default PlayerLayer;
