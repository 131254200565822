import { createContext } from "react";

interface IShapesContext {
	buttonRadius: string;
	inputRadius: string;
	rangeRadius: string;
	selectRadius: string;
	rangeThumbRadius: string;
}

export const defaultShapes: IShapesContext = {
	buttonRadius: "0px",
	inputRadius: "100vw",
	rangeRadius: "100vw",
	selectRadius: "0px",
	rangeThumbRadius: "50%",
};

export const ShapesContext = createContext<IShapesContext>(defaultShapes);
