
import { ICategoryWithParameters } from "../../types";
import { useSelector } from "react-redux";
import {
    getSummary
} from "../../redux/configurationSlice";
interface ICategoryProps {
    category: ICategoryWithParameters;
    actibutt: string;
    transl: any;
    setActibutt: (val: string) => void;
}

const AccessoryButtons: React.FC<ICategoryProps> = ({ category, actibutt, transl, setActibutt }) => {

    const summaryData = useSelector(getSummary);

    function setColour(pcode){
        let color = "";
        let cat = summaryData.find((elem) => elem.category.code === pcode)
        if(cat !== undefined && !cat.isempty){
            if(cat.product.qty > 0){
                color = "--color-green"
            } else {
                if(cat.eta !== ""){
                    color = "--color-yellow"
                } else {
                    color = "--color-red"
                }
            }
        } 
        return color;
    }

    return (
        <>
            <div className="AccessoryButtonsHead">{transl.translations.cabinet_position}</div>
            <div className="AccessoryButtonsHollder">
                {category.parameters
                    .filter((p) => p.visible)
                    .map((p, idx) => (
                        <div key={idx} className={actibutt == p.code ? "AccessoryButtonElem AccessoryButtonElem--active " + setColour(p.code) : "AccessoryButtonElem " + setColour(p.code)} onClick={() => setActibutt(p.code)}>{p.code}</div>
                    ))}
            </div>
        </>
    );
};

export default AccessoryButtons;
