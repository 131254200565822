import { createContext } from "react";

interface IColorsContext {
	lines: string;
	inputLines: string;
	text: string;
	textActive: string;
	bg: string;
	bgActive: string;
	icons: string;
	tooltipText: string;
	tooltipBg: string;
	rangeThumbBg: string;
	rangeThumbBorder: string;
	scrollBg: string;
	scrollThumbBg: string;
}

export const defaultColors: IColorsContext = {
	lines: "#e5e7eb",
	inputLines: "#d1d5db",
	text: "#6b7280",
	bg: "#ffffff",
	bgActive: "#000000",
	textActive: "#ffffff",
	icons: "#9ca3af",
	tooltipText: "#ffffff",
	tooltipBg: "#9ca3af",
	rangeThumbBg: "#374151",
	rangeThumbBorder: "#000000",
	scrollBg: "#ffffff",
	scrollThumbBg: "#9ca3af",
};

export const ColorsContext = createContext<IColorsContext>(defaultColors);
