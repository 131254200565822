import { useContext } from "react";
import { useSelector } from "react-redux";
import { ColorsContext } from "../../contexts/ColorsContext";
import { PlayerContext } from "../../contexts/PlayerContext";
import { getSelectedMaterials } from "../../redux/materialsSlice";
import { IGroup, IMaterial } from "../../types";

interface IMaterialProps {
	group: IGroup;
	material: IMaterial;
}

const Material: React.FC<IMaterialProps> = ({ group, material }) => {
	const playerContext = useContext(PlayerContext);
	const colors = useContext(ColorsContext);
	const selectedMaterial = useSelector(getSelectedMaterials);
	const selectedMaterialId = selectedMaterial[group.id];
	const isSelected = selectedMaterialId === material.id;

	const handleClick = () => {
		if (!playerContext || !playerContext.player) return;

		playerContext.player.setLayoutMediator.assignMaterial(
			group.id,
			material.id
		);
	};

	return (
		<div
			className={`${isSelected ? "tw-border tw-px-2 tw-py-1 material-button material-button--selected" : "material-button"
				} tw-cursor-pointer tw-p-1`}
			onClick={handleClick}
			style={{
				borderColor: colors.lines,
			}}
		>
			<div className="tw-break-words" style={{ color: colors.text }}>
				{playerContext?.player.mediator.translate(material.nazwa)}
			</div>
		</div>
	);
};

export default Material;
