import { useSelector } from "react-redux";
import { getGroups } from "../../redux/materialsSlice";
import Group from "./Group";

const MaterialsLayout = () => {
	const groups = useSelector(getGroups);
	let colorgroup;
	groups.map((gr) => {
		if (gr.id == 4) { colorgroup = gr }
	})

	return (
		<>
			{!groups.length && "Nothing to show."}
			{groups.map((group) => (
				<Group key={group.id} group={group} />
			))}
		</>
	);
};

export default MaterialsLayout;
