import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ColorsContext } from "../../contexts/ColorsContext";
import { useAppDispatch } from "../../redux/hooks";
import Configurationlayout from "../configuration/ConfigurationLayout";
import Accessorylayout from "../configuration/AccessoryLayout";
import SummaryLayout from "../configuration/SummaryLayout"
import MaterialsLayout from "../materials/MaterialsLayout";
import { getTranslations } from "../../redux/translationSlice";
import "./Layout.scss";

import {
	getFullscreen,
	getModes,
	getPlayerLoaded,
	getView,
	setArLoading,
	setDocumentationLoading,
	setSnapshotLoading,
	toggleFullscreen,
	toggleView,
	toggleAccessory,
	toggleSummary,
	toggleEdit,
} from "../../redux/modesSlice";

const viewComps: { [key: string]: React.FC } = {
	edit: Configurationlayout,
	materials: MaterialsLayout,
	accessories: Accessorylayout,
	summary: SummaryLayout,
};

function closeprodDetails(){
	document.querySelector(".productDetailsDrawer")?.classList.remove("productDetailsDrawer--active")
}

const Layout = () => {
	const dispatch = useAppDispatch();
	const view = useSelector(getView);
	const colors = useContext(ColorsContext);
	const ViewComponent = viewComps[view];

	const currenttranslation = useSelector(getTranslations);

	return (
		<>
			<div className="menu-tabs-holder">
				<div className={view == 'edit' ? 'menu-tabs_elm menu-tabs_elm--active' : 'menu-tabs_elm'} onClick={() => dispatch(toggleEdit())}>{currenttranslation.translations.cabinet_configuration}</div>
				<div className={view == 'accessories' ? 'menu-tabs_elm menu-tabs_elm--active' : 'menu-tabs_elm'} onClick={() => dispatch(toggleAccessory())}>{currenttranslation.translations.accessory_select}</div>
				<div className={view == 'summary' ? 'menu-tabs_elm menu-tabs_elm--active' : 'menu-tabs_elm'} onClick={() => dispatch(toggleSummary())}>{currenttranslation.translations.summary}</div>
			</div>

			<div
			id="ConfiguratorContentLayout"
				className="Layout tw-w-full tw-h-full tw-p-4 tw-border-l tw-overflow-y-auto tw-overflow-x-hidden"
				style={
					{
						borderColor: colors.lines,
						"--scrollBg": colors.scrollBg,
						"--scrollThumbBg": colors.scrollThumbBg,
					} as any
				}
			>
				<div id="productDetailsDrawer" className="productDetailsDrawer">
					<div className="detDrawer_head">
						<a className="detDrawer_head--button">{currenttranslation.translations.description}</a>
						<a className="detDrawer_head--button">{currenttranslation.translations.specs}</a>
						<div><a onClick={() => closeprodDetails()}><img src="/icons/closeico.svg"/></a></div>
					</div>

					<div className="swiper mySwiper">
						<div id="productDetailsDrawer_img" className="swiper-wrapper">
							
						</div>
						<div className="swiper-button-next"></div>
						<div className="swiper-button-prev"></div>
					</div>

					<div id="productDetailsDrawer_data"></div>
					<div id="productDetailsDrawer_specs"></div>
				</div>
				<ViewComponent />
				<div className="maincompdivider"></div>
			</div >
		</>
	);
};

export default Layout;
