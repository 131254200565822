import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { ICategory, ICategoryWithParameters, IParameter } from "../types";
import { RootState } from "./store";


interface IConfigurationState {
	parameters: IParameter[];
	categories: ICategory[];
	price: string;
	unit: string;
	bom?: any;
	actualConfiguration?: any;
	basket?: any;
	lang?: string;
	summary? : any;
	actualconfigid? : string;
	customproddata? : any;
}

let  defData =  {
	"1001":{img:"1001.jpg", pricePLN:"1690,00 Zł", dnx:"DNXFLNLQKRMNIK", ean:"5902560363807"},
	"1002":{img:"1002.jpg", pricePLN:"349,00 Zł", dnx:"DNXGFMPOGRMMMO", ean:"5903148913124"},
	"1003":{img:"1003.jpg", pricePLN:"199,00 Zł", dnx:"DNXGFMNPJRMMOP", ean:"5903148912233"},
	"1004":{img:"1004.jpg", pricePLN:"1650,00 Zł", dnx:"DNXGGKHNERMLNP", ean:"5905090330660"},
	"1005":{img:"1005.jpg", pricePLN:"2249,01 Zł", dnx:"DNXGGKHNDRMLMQ", ean:"5905090330653"},
	"1006":{img:"1006.jpg", pricePLN:"3299,00 Zł", dnx:"DNXGGKHMMRMMKJ", ean:"5905090330677"},
	"1007":{img:"1007.jpg", pricePLN:"3799,00 Zł", dnx:"DNXGGJNPJRMMNM", ean:"5905090330073"},
	"1008":{img:"1008.jpg", pricePLN:"699,00 Zł", dnx:"DNXFMJOLFRMMKH", ean:"5902560368222"},
	"1009":{img:"1009.jpg", pricePLN:"659,00 Zł", dnx:"DNXGFPIQKRMMOP", ean:"5903148916101"},
	"1010":{img:"1010.jpg", pricePLN:"99,99 Zł", dnx:"DNXGFMPLMRMMPO", ean:"5903148912967"},
	"1011":{img:"1011.jpg", pricePLN:"39,99 Zł", dnx:"DNXGGHIKIRMLNQ", ean:"5903148917245"},
	"1012":{img:"1012.jpg", pricePLN:"159,99 Zł", dnx:"DNXFMPKQERMMNQ", ean:"5902560369823"},
	"1013":{img:"1013.jpg", pricePLN:"89,99 Zł", dnx:"DNXEFIIOIRMMGL", ean:"5903148917207"},
	"1015":{img:"1015.jpg", pricePLN:"89,99 Zł", dnx:"DNXFMPKPJRMNHN", ean:"5902560369809"},
	"1016":{img:"1016.jpg", pricePLN:"189,99 Zł", dnx:"DNXFMPKQFRMMOP", ean:"5902560369830"},
	"1017":{img:"1017.jpg", pricePLN:"99,99 Zł", dnx:"DNXEFIIOJRMMHK", ean:"5903148917238"},
	"1018":{img:"1018.jpg", pricePLN:"139,99 Zł", dnx:"DNXFMPISJRMNIN", ean:"5902560369663"},
	"1019":{img:"1019.jpg", pricePLN:"99,99 Zł", dnx:"DNXFMPKPLRMNJL", ean:"5902560369816"},
	"1020":{img:"1020.jpg", pricePLN:"99,99 Zł", dnx:"DNXEFIIPDRMLMO", ean:"5902560369847"},
	"1021":{img:"1021.jpg", pricePLN:"199,00 Zł", dnx:"DNXEKPQQMRMNNQ", ean:"5902560363012"},
	"1022":{img:"1022.jpg", pricePLN:"199,00 Zł", dnx:"DNXFMPJSIRMNIL", ean:"5903148912172"},
	"1023":{img:"1023.jpg", pricePLN:"223,96 Zł", dnx:"DNXGFMMOLRMMPI", ean:"5903148912189"},
	"1024":{img:"1024.jpg", pricePLN:"199,00 Zł", dnx:"DNXGFMMOMRMNGH", ean:"5903148912196"},
	"1025":{img:"1025.jpg", pricePLN:"888,92 Zł", dnx:"DNXGGIONIRMMLI", ean:"5903148919065"},
	"1026":{img:"1026.jpg", pricePLN:"485,05 Zł", dnx:"DNXGGIONMRMMOO", ean:"5903148919089"},
	"1027":{img:"1027.jpg", pricePLN:"369,00 Zł", dnx:"DNXGFNPOMRMNIO", ean:"5903148914718"},
	"1028":{img:"1028.jpg", pricePLN:"22,99 Zł", dnx:"DNXFMPISKRMNJM", ean:"5902560369670"},
	"1029":{img:"1029.jpg", pricePLN:"34,99 Zł", dnx:"DNXGFMLKMRMMMI", ean:"5903148911564"},
	"1030":{img:"1030.jpg", pricePLN:"20,41 Zł", dnx:"DNXEFIIPFRMLOM", ean:"5903148911441"},
	"1031":{img:"1031.jpg", pricePLN:"19,91 Zł", dnx:"DNXFMLQLFRMMMN", ean:"5902560368680"},
	"1032":{img:"1032.jpg", pricePLN:"14,27 Zł", dnx:"DNXGGIONERMMHM", ean:"5903148919027"},
	"1033":{img:"1033.jpg", pricePLN:"14,21 Zł", dnx:"DNXGGIONFRMMIL", ean:"5903148919034"},
	"1034":{img:"1034.jpg", pricePLN:"45,99 Zł", dnx:"DNXFMLQLDRMMKP", ean:"5902560368659"},
	"1035":{img:"1035.jpg", pricePLN:"46,99 Zł", dnx:"DNXFMLQLERMMLO", ean:"5902560368666"},
	"1036":{img:"1036.jpg", pricePLN:"46,99 Zł", dnx:"DNXGFMORKRMNIH", ean:"5903148912738"},
	"1037":{img:"1037.jpg", pricePLN:"46,99 Zł", dnx:"DNXGFMORLRMNIQ", ean:"5903148912745"},
	"1038":{img:"1038.jpg", pricePLN:"52,99 Zł", dnx:"DNXGFNKSKRMNGN", ean:"5903148914480"},
	"1039":{img:"1039.jpg", pricePLN:"52,99 Zł", dnx:"DNXGFNKSLRMNHM", ean:"5903148914497"},
	"2017":{img:"2017.jpg", pricePLN:"35,55 Zł", dnx:"DNXGGIONGRMMJK", ean:"5903148919041"},
	"2018":{img:"2018.jpg", pricePLN:"35,55 Zł", dnx:"DNXGGIONHRMMKJ", ean:"5903148919058"},
}

const initialState: IConfigurationState = {
	parameters: [],
	categories: [],
	price: "",
	unit: "",
	bom: [],
	actualConfiguration: [],
	basket: "",
	lang: "",
	summary: [],
	actualconfigid: "",
	customproddata : defData,
};

export const configurationSlice = createSlice({
	name: "configuration",
	initialState,
	reducers: {
		setLang: (state, action: PayloadAction<string>) => {
			state.lang = action.payload;
		},
		setParameters: (state, action: PayloadAction<IParameter[]>) => {
			state.parameters = action.payload;
		},
		setCategories: (state, action: PayloadAction<ICategory[]>) => {
			state.categories = action.payload;
		},
		setPrice: (state, action: PayloadAction<string>) => {
			state.price = action.payload;
		},
		setUnit: (state, action: PayloadAction<string>) => {
			state.unit = action.payload;
		},
		setBom: (state, action: PayloadAction<any>) => {
			state.bom = action.payload;
		},
		setactualConfiguration: (state, action: PayloadAction<any>) => {
			state.actualConfiguration = action.payload;
		},
		setBasket: (state, action: PayloadAction<any>) => {
			state.basket = action.payload;
		},
		setSummary: (state, action: PayloadAction<any>) => {
			state.summary = action.payload;
		},
		setSaveddata: (state, action: PayloadAction<any>) => {
			state.actualconfigid = action.payload;
		},

		setCustomProddata: (state, action: PayloadAction<any>) => {
			state.customproddata = action.payload;
		},
		
	},
});

export const { setParameters, setCategories, setPrice, setUnit, setBom, setactualConfiguration, setBasket, setLang, setSummary, setSaveddata, setCustomProddata } =
	configurationSlice.actions;

// selectors
export const getConfiguration = (state: RootState) => state.configurationSlice;
export const getCategories = createSelector(
	getConfiguration,
	(state) => state.categories
);
export const getCustomproddata = createSelector(
	getConfiguration,
	(state) => state.customproddata
);
export const getVisibleCategories = createSelector(
	getCategories,
	(categories) => categories.filter((c) => c.visible)
);
export const getParameters = createSelector(
	getConfiguration,
	(state) => state.parameters
);

export const getBom = createSelector(
	getConfiguration,
	(state) => state.bom
);
export const getSaveddata = createSelector(
	getConfiguration,
	(state) => state.actualconfigid
);

export const getBomProducts = createSelector(
	getConfiguration,
	(state) => state.actualConfiguration
);

export const getBasket = createSelector(
	getConfiguration,
	(state) => state.basket
);

export const getLang = createSelector(
	getConfiguration,
	(state) => state.lang
);
export const getSummary = createSelector(
	getConfiguration,
	(state) => state.summary
);

export const getCustomProdData = createSelector(
	getConfiguration,
	(state) => state.customproddata
);

export const getVisibleCategoriesWithParameters = createSelector(
	getConfiguration,
	({ parameters, categories }): ICategoryWithParameters[] => {
		return categories
			.filter((c) => c.visible)
			.map((category) => {
				const categoryParameters = category.params
					.map((paramCode) =>
						parameters.find((param) => param.code === paramCode)
					)
					.filter((param) => !!param) as IParameter[];

				return {
					...category,
					parameters: categoryParameters,
				};
			});
	}
);
export const getPrice = createSelector(
	getConfiguration,
	(state) => state.price
);
export const getUnit = createSelector(getConfiguration, (state) => state.unit);

export default configurationSlice.reducer;
