import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ReactReduxContext } from 'react-redux';
import { PlayerContext } from "../contexts/PlayerContext";
import {
	getUnit,
	setCategories,
	setParameters,
	setPrice,
	setUnit,
	setBom,
	getBom,
	setactualConfiguration,
	setBasket,
	setLang,
	getVisibleCategoriesWithParameters,
	setSummary,
	getSaveddata,
	setCustomProddata,
	getCustomproddata
} from "../redux/configurationSlice";
import { useAppDispatch } from "../redux/hooks";
import { setGroups, setSelectedMaterials } from "../redux/materialsSlice";
import { setPlayerLoaded } from "../redux/modesSlice";

interface IPlayerProps {
	id: string;
	langCode: string;
	documentationTitle: string;
	configurationId: string;
	dataToLoad: string;
	cartUrl: string;
}

const Player: React.FC<IPlayerProps> = ({
	id,
	langCode,
	documentationTitle,
	configurationId,
	dataToLoad,
	cartUrl
}) => {
	const dispatch = useAppDispatch();
	const playerContext = useContext(PlayerContext);
	const playerRef = useRef<any>(null);
	const selectedUnit = useSelector(getUnit);

	const [playerBom, setPlayerBom] = useState<any | null>(null);
	const [actualBom, setActualBom] = useState<any | null>(null);
	const MainCategories = useSelector(getVisibleCategoriesWithParameters);
	const Actualconfigid = useSelector(getSaveddata);

	//  use effect do refreshu -----------------------------------------------------
		useEffect(() => {
			if(Actualconfigid != ""){
				setTimeout(()=>{
					updatePlayer();
				},10000)
				
			}
		}, [Actualconfigid]);
	//  ----------------------------------------------------------------------------


	const updatePlayer = async () => {
		const playerDOM = playerRef.current;
		if (!playerDOM || !playerContext) return;
		const player = playerDOM.player;
		playerContext.player = player;
		playerContext.webComponent = playerRef.current;
		player.setSelected(player.setRoots.filterScenes()[0]);
		const { categories, params } = player.mediator.getConfigurationData();
		dispatch(setParameters(params));
		dispatch(setLang(langCode))
		dispatch(setCategories(categories));
		dispatch(setUnit(player.targetUnit));
		const groupData = await player.setLayoutMediator.getGroupData();
		dispatch(setGroups(groupData));
		const selectedMaterials = player.setGroupsMaterial;
		dispatch(setSelectedMaterials(selectedMaterials));

		// Dispatching BOM
		const userBom: any[] = [];
		let promMain = playerDOM.player.bom.getBom(9, 9);
		let proAcc = playerDOM.player.bom.getBom(10, 10);

		let [pmain, pacc] = await Promise.all([promMain, proAcc]);
		userBom.push(pmain.body)
		userBom.push(pacc.body)
		dispatch(setBom(userBom));
		setPlayerBom(userBom);
	};

	const updatePrice = () => {
		const playerDOM = playerRef.current;
		if (!playerDOM) return;
		const player = playerDOM.player;
		const price = player.mediator.getSetCurrencyPrice();
		//dispatch(setPrice(price));
	};

	function updateDocument(name: any, name2: any): any {
		const playerDOM = playerRef.current;
		if (!playerDOM) return;
		const player = playerDOM.player;
		if (name.case[0] && name.case[0].EAN != undefined) {
			player.mediator.changeParameter("dok_ean", name.case[0].EAN)
		}
		if (name.case[0] && name.case[0].Nazwa != undefined) {
			let nazw = name.case[0].Nazwa.toString();
			player.mediator.changeParameter("model_szafy", nazw)
		}
		if (name2 != undefined) {
			player.mediator.changeParameter("cena_calosciowa", name2)
		}
	}

	useEffect(() => {
		if (!selectedUnit) return;
		updatePlayer();
		// eslint-disable-next-line
	}, [selectedUnit]);

	useEffect(() => {
		if (!playerRef || !playerRef.current) return;
		const playerDOM = playerRef.current;

		const initEvents = () => {
			if (!playerDOM) return;

			dispatch(setPlayerLoaded(true));
			updatePlayer();
			updatePrice();
			playerDOM.addEventListener("updatePrice", updatePrice);
			playerDOM.addEventListener("playerReloaded", updatePlayer);
			// playerDOM.addEventListener("playerSceneReloaded", updatePlayer);
		};

		playerDOM.addEventListener("playerLoaded", initEvents);

		return () => {
			playerDOM.removeEventListener("playerLoaded", initEvents);
			// playerDOM.addEventListener("updatePrice", updatePrice);
			playerDOM.removeEventListener("updatePrice", updatePrice);
			playerDOM.removeEventListener("playerReloaded", updatePlayer);
			playerDOM.removeEventListener("playerSceneReloaded", updatePlayer);
		};
		// eslint-disable-next-line

	}, [playerRef, dispatch]);

	const actu = useSelector(getCustomproddata);

	useEffect(() => {
		if (playerBom != null && JSON.stringify(actualBom) !== JSON.stringify(playerBom)) {
			setActualBom(playerBom)
			// Zapytanie o ceny i produkty
			postData(playerBom)
			let defData2 = JSON.parse(JSON.stringify(actu));
			fetch(process.env.REACT_APP_URL2+'/variantic', {
				method: 'get',
			})
			.then(resp => {
			resp.text().then(c => {
				const o = JSON.parse(c);
				Object.entries(defData2).map((item)=>{
					if (typeof item[1] === "object" && item[1] ) {
						// message gets narrowed to string!
						const ean = item[1]["ean"];
						let finded = o.find((el) => el.ean == ean)	
						if(typeof finded != "undefined"){
							item[1]["pricePLN"] = finded.price.gross + " zł";
							item[1]["qty"] = finded.qty;
							item[1]["eta"] = finded.eta;
							//item[1]["qty"] = finded.price.gross + " zł";
						}		
					}
				})
				dispatch(setCustomProddata(defData2));
			})
			})

			//dispatch(setCustomProddata(defData));
		}
	}, [playerBom, dispatch]);

	async function postData(data: any) {
		// Setup data to send
		let datatosend: object[] = [{}, []];
		let mapfrom: object[] = [];
		let mapto: object[] = [];

		// Build objects

		let datatosendnew = {
			config: {},
		};
		
		data[0].map((itemc: any) => {
			let type: any = datatosend[0]
			if (itemc[0] === "TYP") { datatosendnew["config"]["varianticType"] = parseInt(itemc[1]) }
			if (itemc[0] === "RODZAJ") { datatosendnew["config"]["varianticKind"] = parseInt(itemc[1]) }
			if (itemc[0] === "DRZWI") { datatosendnew["config"]["varianticDoors"] = parseInt(itemc[1]) }
			if (itemc[0] === "WYSOKOSC") { datatosendnew["config"]["varianticHeight"] = parseInt(itemc[1]) }
			if (itemc[0] === "SZEROKOSC") { datatosendnew["config"]["varianticWidth"] = parseInt(itemc[1]) }
			if (itemc[0] === "GLEBOKOSC") { datatosendnew["config"]["varianticDepth"] = parseInt(itemc[1]) }
			if (itemc[0] === "KOLOR") { datatosendnew["config"]["varianticColor"] = parseInt(itemc[1]) }
		})

		let prodd: any[] = [];
		data[1].map((itemp: any) => {
			mapfrom.push(itemp[0])
			prodd.push({"ean": itemp[0], "position": itemp[1]})
		})
		datatosendnew["products"] = prodd;
		let url = process.env.REACT_APP_URL1+"/pl/v2/product";
		const response = await fetch(url, {
			method: "POST",
			mode: "cors",
			credentials: "same-origin",
			headers: { "Content-Type": "application/json"},
			body: JSON.stringify(datatosendnew),
		});
		if (!response.ok) {
			const message = `An error has occured: ${response.status}`;
		}
		const items = await response.json();
		let itemspar = items;


		let activecats: object[] = [];
		MainCategories[1].parameters.map((acticat)=>{
			if(acticat.visible){
				let prd = itemspar["products"].find((element) => element.position === acticat?.name);
				let cat = {
					category: acticat,
					product: prd != undefined ? prd : {},
					isempty: prd != undefined ? false : true,
					isavai: prd != undefined && prd.qty > 0 ? true : false,
					eta: prd != undefined && prd.eta
				};
				activecats.push(cat)
			}
		})
		
		dispatch(setSummary(activecats));

		/*
		mapfrom.map(itt => {
			itemspar.products.map((itpp: any) => {
				if (itpp.ean === itt) {
					mapto.push(itpp);
				}
			})
		});
		itemspar.products = mapto;
		*/

		let itemtax: string = "";
		if (itemspar.taxstatus) {
			itemtax = itemspar.currency + " z VAT " + itemspar.tax;
		} else {
			itemtax = " bez VAT";
		}
		dispatch(setactualConfiguration(itemspar))

		// basket  construct
		let appbasket: any = [];
		let appbasketobject: any = {};
		let appsummaryp: any = 0;
		// add case dnx to basket

		if (itemspar.case_products.length > 0) {
			if (itemspar.case_products[0].qty > 0) {
				appsummaryp = appsummaryp + itemspar.case_products[0].price_PLN;
				if (itemspar.case_products[0].product_id in appbasketobject) {
					appbasketobject[itemspar.case[0].dnx] = appbasketobject[itemspar.case_products[0].product_id] + 1;
					//appbasketobject[itemspar.case[0].dnx] = appbasketobject[itemspar.case[0].dnx] + 1;
				} else {
					//appbasketobject[itemspar.case[0].dnx] = 1;
					appbasketobject[itemspar.case_products[0].product_id] = 1;
				}
			}
		}

		// add product dnx to basket
		if (itemspar.products.length > 0) {
			itemspar.products.map((item: any) => {
				if (item.qty > 0) {
					appsummaryp = appsummaryp + item.price_PLN;
					if (item.product_id in appbasketobject) {
						appbasketobject[item.ean] = appbasketobject[item.ean] + 1;
					} else {
						appbasketobject[item.ean] = 1;
					}
				}
			})
		}

		Object.keys(appbasketobject).forEach(function (key, index) {
			appbasket.push(key + "*" + appbasketobject[key])
		});

		let totalp = "0zł";
		totalp = appsummaryp.toFixed(2) + itemtax;
		updateDocument(itemspar, totalp)
		dispatch(setPrice(appsummaryp.toFixed(2) + itemtax));
		dispatch(setBasket(appbasket.toString()))

		return items;
	}

	return (
		<variantic-player
			ref={playerRef}
			component-id={id}
			disable-layout
			width="100%"
			height="100%"
			lang-code={langCode}
			layout-id="-1"
			documentation-title={documentationTitle}
			configuration-id={configurationId}
			data-to-load={dataToLoad}
			cart-url={cartUrl}
		></variantic-player>
	);
};

export default Player;
