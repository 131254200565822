import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    getUnit,
    getVisibleCategoriesWithParameters,
} from "../../redux/configurationSlice";
import Category from "./AccessorySelect";
import Buttons from "./AccessoryButtons";
import { getTranslations } from "../../redux/translationSlice";

const Accessorylayout = () => {
    const categories = useSelector(getVisibleCategoriesWithParameters);
    const selectedUnit = useSelector(getUnit);
    const [actibutt, setActibutt] = useState('');
    const currenttranslation = useSelector(getTranslations);

    return (
        
        <div>
            <div className="legendholder">
                <div style={{color: "red"}}>{currenttranslation.translations.warn2}</div>
            </div>
            {categories == undefined 
            ? <></> 
            : 
            (
                <>
                <div>
                    {categories[1] && <Buttons  key={`${selectedUnit}-${categories[1].id}-cat1`} category={categories[1]} actibutt={actibutt} setActibutt={setActibutt} transl={currenttranslation} />}
                </div>
                <div>
                    {categories[1] && <Category key={`${selectedUnit}-${categories[1].id}-cat1`} category={categories[1]} actibutt={actibutt} transl={currenttranslation} />}
                </div>
                </>
            )
            }
           
        </div>
    );
};

export default Accessorylayout;
