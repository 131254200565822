import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import {
	getUnit,
	getVisibleCategoriesWithParameters,
	
} from "../../redux/configurationSlice";
import Category from "./Category";
import UnitPicker from "./UnitPicker";
import { getGroups } from "../../redux/materialsSlice";
import { getBomProducts, getLang } from "../../redux/configurationSlice";
import {getCustomProdData} from "../../redux/configurationSlice";


const Configurationlayout = () => {
	const products = useSelector(getBomProducts);
	const prodcustomdata = useSelector(getCustomProdData);
	const langu = useSelector(getLang);
	const categories = useSelector(getVisibleCategoriesWithParameters);
	const selectedUnit = useSelector(getUnit);


	let caseqty;
	let caseeta;

	if(typeof products.case_products != "undefined" && typeof products.case_products[0] != "undefined"){
		caseqty = products.case_products[0].qty;
		caseeta = products.case_products[0].qty;
	} else {
		caseqty = 0;
		caseeta = "";
	}

	let groups = useSelector(getGroups);
	// zostawiamy tylko grupę kolorów
	groups.map((gr) => { if (gr.id == 4) { groups = [gr] } })

	function productStatus(qty, eta){
		let info;
		if(qty == 0 && eta != ""){info = <><div className="itemqty--status qitem-qty--status-yellow qitem-qty--status-big red-info--holder"><div className="ico"><img alt="case" src="/icons/warning-circled-outline.svg"/></div>produkt w trakcie dostawy</div><div className="itemqty--status qitem-qty--status-yellow">Planowana dostawa: {eta}</div></>} 
		else {
			if(Object.entries(products).length > 0){
				if(qty > 0 ){info = <div className="itemqty--status qitem-qty--status-green qitem-qty--status-big red-info--holder"><div className="ico"><img alt="case" src="/icons/check-circled-outline.svg"/></div>produkt dostępny</div>}
				else{info = <div className="red-info--holder"><div className="itemqty--status qitem-qty--status-red qitem-qty--status-big"><div className="ico"><img alt="case" src="/icons/warning-circled-outline.svg"/></div>Produkt niedostępny</div><div className="red-info-small">Możesz kontynuować konfigurację, wybrać wyposażenie, ale szafa nie będzie dodana do koszyka</div></div>}
			} else {
				info = "";
			}
		}

		return <div>{info}</div>
	}

	useEffect(()=>{
		console.log("effect")

	},[])

	return (
		<div className="configLayout-holder">
			<div>{productStatus(caseqty, caseeta)}</div>
			{categories[0] && <Category key={`${selectedUnit}-${categories[0].id}-cat0`} category={categories[0]} />}
			{!groups.length && "Loading..."}
		</div>
	);
};

export default Configurationlayout;
