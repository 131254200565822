export const convertUnit = (
	player: any,
	value: string | number,
	unit: string,
	min?: number,
	max?: number
) => {
	const baseUnit = player.unitConverter.vaildUnit(unit) ? "mm" : unit;

	let convertedInputValue = player.mediator.convert(
		value,
		player.targetUnit,
		baseUnit
	);
	if (max && convertedInputValue > max) convertedInputValue = max;

	if (min && convertedInputValue < min) convertedInputValue = min;

	const newInputValue = player.mediator.convert(
		convertedInputValue,
		baseUnit,
		player.targetUnit
	);

	return {
		value: convertedInputValue,
		inputValue: newInputValue,
	};
};
