import { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom/client";
import { useSelector } from "react-redux";
import { getSummary } from "../../redux/configurationSlice";
import { getBomProducts, getLang } from "../../redux/configurationSlice";
import { getTranslations } from "../../redux/translationSlice";
import {
	setDocumentationLoading,
} from "../../redux/modesSlice";
import { useAppDispatch } from "../../redux/hooks";
import { PlayerContext } from "../../contexts/PlayerContext";


const SummaryLayout = () => {
    const playerContext = useContext(PlayerContext);
    const player = playerContext?.player;

    const rootDOM = document.getElementById("variantic-layout") as HTMLElement;
    const props: any = rootDOM.dataset;
    props["cartUrl"] = rootDOM.getAttribute("cart-url")
    props["cartType"] = rootDOM.getAttribute("cart-type")
    props["hidePrice"] = rootDOM.getAttribute("hide-price")
    props["hideBasket"] = rootDOM.getAttribute("hide-basket")

    const dispatch = useAppDispatch();
    const currenttranslation = useSelector(getTranslations);
    const [bomCase, setbomCase] = useState<any>([]);
    const [summarystatus, setSummarystatus] = useState<any>(true);
    const [bomProducts, setbomProducts] = useState<any>([]);
    const products = useSelector(getBomProducts);
    const langu = useSelector(getLang);
    const summaryData = useSelector(getSummary);
    function findArrayElementByLng(array) {
        const found = array.find((element) => element.lng === langu?.toUpperCase());
        return found.name
    }

    function prosClassnames (product){
        let productClass: string[] = [];
        if(product.isavai === false){productClass.push("summaryLayout-item--noactive");}
        if(product.qty === 0 && product.eta !== ""){productClass.push("summaryLayout-item--eta");}
        let cla = productClass.join(' '); 
        return cla;
    }

    const downloadDocumentation = () => {
		dispatch(setDocumentationLoading(true));
		try {
			player.documentation
				.getSetDocumentations(player.setRoots)
				.catch(() => dispatch(setDocumentationLoading(false)))
				.then(() => dispatch(setDocumentationLoading(false)));
		} catch (e) {
			console.error(e);
			dispatch(setDocumentationLoading(false));
		}
        setSummarystatus(false)
        setTimeout(()=>{
            setSummarystatus(true)
        },10000)
	};

    function prosIco(product, isempty){
        let ico = "";
        if(isempty){
            ico = "https://www.batna24.com/js/configurator-rack/icons/warning-circled-outline.svg";
        } else {
            if(product.qty > 0){
                ico = "https://www.batna24.com/js/configurator-rack/icons/check-circled-outline.svg";
            } else {
                if(product.eta !== ""){
                    ico = "https://www.batna24.com/js/configurator-rack/icons/warning-eta.svg"
                } else {
                    ico = "https://www.batna24.com/js/configurator-rack/icons/warning-circled-outline.svg";
                }
            }
        }
        
        return ico;
    }

    useEffect(() => {
        setbomProducts(products.products)
        setbomCase(products.case_products)
    }, [useSelector(getBomProducts)]);

    return (
        <div>
            <div className="legendholder">
                <div className="check"><img alt="available" src="https://www.batna24.com/js/configurator-rack/icons/check-circled-outline.svg"></img> {currenttranslation.translations.product_available}</div>
                <div className="info"><img alt="eta" src="https://www.batna24.com/js/configurator-rack/icons/warning-eta.svg"></img>{currenttranslation.translations.eta_info}</div>
                <div className="warn"><img alt="warning" src="https://www.batna24.com/js/configurator-rack/icons/warning-circled-outline.svg"></img>{currenttranslation.translations.product_unavailable}</div>
                <div>{currenttranslation.translations.warn2}</div>
            </div>


                
                    {summarystatus
                    ?(            
                    <div className="summary-layout-get-summary--holder">
                        <button className="summary-layout-get-summary" onClick={downloadDocumentation}>
                            <img src="/icons/get_summary_ico.svg" alt="pdf" />
                            <div>{currenttranslation.translations.get_summary}</div>
                        </button>
                    </div>
                    )
                    :  (                
                    <div className="summary-layout-get-summary--holder">
                        <button className="summary-layout-get-summary" >
                            <div>Generowanie zestawienia ...</div>
                        </button>
                    </div>
                    )}
                


            

            <div>
                <div className="summaryLayout-header">{currenttranslation.translations.choosen_cabinet}</div>

                {bomCase && !bomCase.length && <div className="text-no-product">{currenttranslation.translations.nothing_choosen}</div>}
                {bomCase && bomCase.length > 0 &&
                    <div className={bomCase[0].qty < 1 ? "summaryLayout-item summaryLayout-item-hover summaryLayout-item--noactive" : "summaryLayout-item summaryLayout-item-hover"}>
                        <div className="text">{findArrayElementByLng(bomCase[0].names)}</div>
                        <div className="ean">{bomCase[0].ean}</div>
                        <div className="ico">
                            <img alt="case" src={bomCase[0].qty < 1 ? "https://www.batna24.com/js/configurator-rack/icons/warning-circled-outline.svg" : "https://www.batna24.com/js/configurator-rack/icons/check-circled-outline.svg"}></img>
                        </div>
                    </div>
                }
                

                <div className="summaryLayout-header">{currenttranslation.translations.choosen_accessories}</div>
                <div >
                {summaryData && summaryData.map(({ category, product, isempty }: any) =>
                    <div className={isempty ? "summaryLayout-item " + prosClassnames(product) : "summaryLayout-item summaryLayout-item-hover " + prosClassnames(product)} > 
                        <div className="code">{category.name}</div>
                        {isempty 
                        ? <div className="text">{currenttranslation.translations.empty}</div>
                        : <><div className="text">{findArrayElementByLng(product.names)}</div><div className="ean">{product.ean}</div></>
                        }
                        <div className={isempty ? "ico icoempty" : "ico"}>
                            <img alt="extralink" src={prosIco(product, isempty)}></img>
                        </div>
                    </div>
                )}


		
                </div>

            </div>
        </div>
    );
};

export default SummaryLayout;
