import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { View } from "../types";
import { RootState } from "./store";

//
const translationobject = {
    "pl": {
        "cabinet_configurator": "Konfigurator szaf",
        "warn1": "Do koszyka będą dodane tylko dostępne produkty",
        "warn2": "Uwaga: do koszyka zostaną dodane tylko dostępne produkty.",
        "basket_add": "Do koszyka",
        "cabinet_configuration": "Konfiguracja szafy",
        "accessory_select": "Wybór wyposażenia",
        "summary": "Podsumowanie",
        "cabinet_position": "Pozycja w szafie",
        "choose_product_position": "Wybierz produkt do pozycji",
        "choose_product_list": "Wpisz nazwę produktu lub wybierz go z listy",
        "product_available": "Produkt dostepny na stanie",
        "product_unavailable": "Produkt niedostepny na stanie",
        "choosen_cabinet": "Wybrana szafa:",
        "choosen_accessories": "Wybrane akcesoria:",
        "nothing_choosen": "Brak wybranych produktów",
        "get_summary": "Pobierz zestawienie",
        "save_image": "Zapisz obraz",
        "open_ar": "Otwórz w AR",
        "full_screen": "Pełny ekran",
        "close": "Zamknij",
        "dimm_units": "Jednostki miary",
        "empty": "Puste",
        "empty_field": "Puste",
        "eta_info": "Produkt w trakcie dostawy",
        "remove": "Usuń",
        "search": "Szukaj",
        "description": "Opis",
        "specs": "Specyfikacja",
        "shipdate": "Data dostawy",
    },
    "de": {
        "cabinet_configurator": "Schrank Konfigurator",
        "warn1": "Es werden nur verfügbare Produkte in den Warenkorb gelegt",
        "warn2": "Warnung: Es werden nur verfügbare Produkte in den Warenkorb gelegt.",
        "basket_add": "In den Warenkorb",
        "cabinet_configuration": "cabinet_configuration",
        "accessory_select": "Geräteauswahl",
        "summary": "zusammenfassung",
        "cabinet_position": "Schrankposition",
        "choose_product_position": "Produkt zur Position wählen",
        "choose_product_list": "Geben Sie einen Produktnamen ein oder wählen Sie ihn aus einer Liste",
        "product_available": "Produkt auf Lager verfügbar",
        "product_unavailable": "Produkt nicht auf Lager verfügbar",
        "choosen_cabinet": "gewählter Schrank:",
        "choosen_accessories": "gewähltes Zubehör:",
        "nothing_choosen": "Keine ausgewählten Produkte (Zubehör)",
        "get_summary": "Zusammenfassung abrufen",
        "save_image": "Bild speichern",
        "open_ar": "Öffnen in AROOpen in AR",
        "full_screen": "fullscreen",
        "close": "schließen",
        "dimm_units": "Dimm-Einheiten",
        "empty": "Leer",
        "empty_field": "Leer",
        "eta_info": "Produkt in Lieferung",
        "remove": "Entfernen",
        "search": "Suche",
        "description": "Beschreibung",
        "specs": "Spezifikation",
        "shipdate": "Lieferdatum",
    },
    "en": {
        "cabinet_configurator": "Cabinet Configurator",
        "warn1": "Only available products will be added to the cart",
        "warn2": "Note: Only available items will be added to your cart.",
        "basket_add": "Add to cart",
        "cabinet_configuration": "Cabinet configuration",
        "accessory_select": "Accessory Selection",
        "summary": "Summary",
        "cabinet_position": "Cabinet Position",
        "choose_product_position": "Choose a product to position",
        "choose_product_list": "Enter a product name or choose from a list",
        "product_available": "Product in stock",
        "product_unavailable": "Product out of stock",
        "choosen_cabinet": "Selected cabinet:",
        "choosen_accessories": "Selected accessories:",
        "nothing_choosen": "No selected products",
        "get_summary": "Get summary",
        "save_image": "Save image",
        "open_ar": "Open in AR",
        "full_screen": "Full screen",
        "close": "Close",
        "dimm_units": "Dimmension units",
        "empty": "Empty",
        "empty_field": "Empty",
        "eta_info": "Product under delivery",
        "remove": "Remove",
        "search": "Search",
        "description": "Description",
        "specs": "Specification",
        "shipdate": "Delivery date",
    },
    "es": {
        "cabinet_configurator": "Configurador de Gabinete",
        "warn1": "Solo se añadirán al carrito los productos disponibles",
        "warn2": "Nota: solo se agregarán a tu carrito los artículos disponibles.",
        "basket_add": "Añadir a la cesta",
        "cabinet_configuration": "Configuración del Gabinete",
        "accessory_select": "Selección de Accesorios",
        "summary": "Resumen",
        "cabinet_position": "Posición del Gabinete",
        "choose_product_position": "Elija un producto para posicionar",
        "choose_product_list": "Ingrese un nombre de producto o elija de una lista",
        "product_available": "Producto en stock",
        "product_unavailable": "Producto no disponible",
        "choosen_cabinet": "Vestuario seleccionado:",
        "choosen_accessories": "Accesorios seleccionados:",
        "nothing_choosen": "No hay productos seleccionados (accesorios)",
        "get_summary": "Obtener resumen",
        "save_image": "Guardar imagen",
        "open_ar": "Abrir en AROabrir en AR",
        "full_screen": "Pantalla completa",
        "close": "Cerrar",
        "dimm_units": "Unidades dimm",
        "empty": "Vacío",
        "empty_field": "Vacío",
        "eta_info": "Producto en entrega",
        "remove": "Eliminar",
        "search": "Buscar",
        "description": "Descripción",
        "specs": "Especificación",
        "shipdate": "Fecha de entrega",
    },
    "it": {
        "cabinet_configurator": "Configuratore armadio",
        "warn1": "Solo i prodotti disponibili verranno aggiunti al carrello",
        "warn2": "Nota: solo gli articoli disponibili verranno aggiunti al tuo carrello.",
        "basket_add": "Aggiungi al carrello",
        "cabinet_configuration": "Configurazione armadio",
        "accessory_select": "Selezione accessori",
        "summary": "Sommario",
        "cabinet_position": "Posizione armadio",
        "choose_product_position": "Scegli un prodotto da posizionare",
        "choose_product_list": "Inserisci il nome di un prodotto o scegli da un elenco",
        "product_available": "Prodotto disponibile",
        "product_unavailable": "Prodotto non disponibile",
        "choosen_cabinet": "Armadio selezionato:",
        "choosen_accessories": "Accessori selezionati:",
        "nothing_choosen": "Nessun prodotto selezionato",
        "get_summary": "Ottieni riepilogo",
        "save_image": "Salva immagine",
        "open_ar": "Apri in ARopen in AR",
        "full_screen": "Schermo intero",
        "close": "Chiudi",
        "dimm_units": "Unità dimm",
        "empty": "Vuoto",
        "empty_field": "Vuoto",
        "eta_info": "Prodotto in consegna",
        "remove": "Rimuovi",
        "search": "Cerca",
        "description": "Descrizione",
        "specs": "Specifiche",
        "shipdate": "Data di consegna",
    },
    "pt": {
        "cabinet_configurator": "Configurador de Gabinete",
        "warn1": "Apenas produtos disponíveis serão adicionados ao carrinho",
        "warn2": "Nota: Apenas itens disponíveis serão adicionados ao seu carrinho.",
        "basket_add": "Adicionar ao cesto",
        "cabinet_configuration": "Configuração do gabinete",
        "accessory_select": "Seleção de acessórios",
        "summary": "Resumo",
        "cabinet_position": "Posição do Armário",
        "choose_product_position": "Escolha um produto para posicionar",
        "choose_product_list": "Digite um nome de produto ou escolha em uma lista",
        "product_available": "Produto em estoque",
        "product_unavailable": "Produto não disponível",
        "choosen_cabinet": "Guarda-roupa selecionado:",
        "choosen_accessories": "Acessórios selecionados:",
        "nothing_choosen": "Nenhum produto selecionado",
        "get_summary": "Obter resumo",
        "save_image": "Salvar imagem",
        "open_ar": "Abrir em AROabrir em AR",
        "full_screen": "Tela cheia",
        "close": "Fechar",
        "dimm_units": "Unidades dimm",
        "empty": "Vazio",
        "empty_field": "Vazio",
        "eta_info": "Produto em entrega",
        "remove": "Remover",
        "search": "Pesquisar",
        "description": "Descrição",
        "specs": "Especificação",
        "shipdate": "Data de entrega",
    },
    "ru": {
        "cabinet_configurator": "Конфигуратор шкафов",
        "warn1": "В корзину будут добавлены только доступные товары",
        "warn2": "Примечание: в вашу корзину будут добавлены только доступные товары.",
        "basket_add": "Добавить в корзину",
        "cabinet_configuration": "Конфигурация кабинета",
        "accessory_select": "Выбор аксессуаров",
        "summary": "Сводка",
        "cabinet_position": "Позиция кабинета",
        "choose_product_position": "Выберите продукт для позиционирования",
        "choose_product_list": "Введите название продукта или выберите из списка",
        "product_available": "Товар в наличии",
        "product_unavailable": "Товар отсутствует на складе",
        "choosen_cabinet": "Выбранный шкаф:",
        "choosen_accessories": "Выбранные аксессуары:",
        "nothing_choosen": "Нет выбранных товаров",
        "get_summary": "Получить сводку",
        "save_image": "Сохранить изображение",
        "open_ar": "Открыть в AROоткрыть в AR",
        "full_screen": "Полный экран",
        "close": "Закрыть",
        "dimm_units": "Единицы диммирования",
        "empty": "Пустой",
        "empty_field": "Пустой",
        "eta_info": "Товар в стадии доставки",
        "remove": "Удалить",
        "search": "Поиск",
        "description": "Описание",
        "specs": "Спецификация",
        "shipdate": "Дата доставки",
    },
    "tr": {
        "cabinet_configurator": "Kabin Yapılandırıcı",
        "warn1": "Sepete yalnızca mevcut ürünler eklenecektir",
        "warn2": "Not: Sepetinize sadece mevcut ürünler eklenecektir.",
        "basket_add": "Sepete ekle",
        "cabinet_configuration": "Kabin Yapılandırması",
        "accessory_select": "Aksesuar Seçimi",
        "summary": "Özet",
        "cabinet_position": "Kabin Konumu",
        "choose_product_position": "Konumlandırmak için bir ürün seçin",
        "choose_product_list": "Bir ürün adı girin veya listeden seçin",
        "product_available": "Stoktaki ürün",
        "product_unavailable": "Ürün stokta yok",
        "choosen_cabinet": "Seçilen gardırop:",
        "choosen_accessories": "Seçilen aksesuarlar:",
        "nothing_choosen": "Seçili ürün yok",
        "get_summary": "Özeti al",
        "save_image": "Resmi kaydet",
        "open_ar": "AR'de AROopen'da aç",
        "full_screen": "Tam ekran",
        "close": "Kapat",
        "dimm_units": "Dimm birimleri",
        "empty": "Boş",
        "empty_field": "Boş",
        "eta_info": "Ürün teslim ediliyor",
        "remove": "Kaldır",
        "search": "Arama",
        "description": "Açıklama",
        "specs": "Şartname",
        "shipdate": "Teslimat tarihi",
    },
    "cz": {
        "cabinet_configurator": "Konfigurátor skříněk",
        "warn1": "Do košíku budou přidány pouze dostupné produkty",
        "warn2": "Poznámka: Do košíku budou přidány pouze dostupné položky.",
        "basket_add": "Přidat do košíku",
        "cabinet_configuration": "Konfigurace skříně",
        "accessory_select": "Výběr příslušenství",
        "summary": "Shrnutí",
        "cabinet_position": "Umístění skříně",
        "choose_product_position": "Vyberte produkt na pozici",
        "choose_product_list": "Zadejte název produktu nebo vyberte ze seznamu",
        "product_available": "Produkt na skladě",
        "product_unavailable": "Produkt není skladem",
        "choosen_cabinet": "Vybraná skříň:",
        "choosen_accessories": "Vybrané příslušenství:",
        "nothing_choosen": "Žádné vybrané produkty",
        "get_summary": "Získat souhrn",
        "save_image": "Uložit obrázek",
        "open_ar": "Otevřít v AROopen v AR",
        "full_screen": "Celá obrazovka",
        "close": "Zavřít",
        "dimm_units": "Jednostní miary",
        "empty": "Prázdný",
        "empty_field": "Prázdný",
        "eta_info": "Produkt v dodávce",
        "remove": "Odstranit",
        "search": "Hledat",
        "description": "Popis",
        "specs": "Specifikace",
        "shipdate": "Datum dodání",
    },
};


interface ITranslationState {
    translations?: any;
}

const initialState: ITranslationState = {
    translations: {
        "cabinet_configurator": "",
        "warn1": "",
        "warn2": "",
        "basket_add": "",
        "cabinet_configuration": "",
        "accessory_select": "",
        "summary": "",
        "cabinet_position": "",
        "choose_product_position": "",
        "choose_product_list": "",
        "product_available": "",
        "product_unavailable": "",
        "choosen_cabinet": "",
        "choosen_accessories": "",
        "nothing_choosen": "",
        "get_summary": "",
        "save_image": "",
        "open_ar": "",
        "full_screen": "",
        "close": "",
        "dimm_units": "",
        "empty": "",
        "empty_field": "",
        "eta_info": "",
    },
};

export const translationSlice = createSlice({
    name: "translations",
    initialState,
    reducers: {
        setTranslation: (state, action: PayloadAction<any>) => {
            state.translations = translationobject[action.payload as keyof typeof translationobject]
        },
    },
});
export const { setTranslation } = translationSlice.actions;

// selectors
export const getTranslations = (state: RootState) => state.translationSlice;
export const getCurrentTranslations = (state: RootState) => state.translationSlice.translations.def;

export default translationSlice.reducer;
